import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Link from "../components/Link";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const CurrentProducts = ({ products }) => {
  return (
    <section className="section has-no-paddings-top has-background-random-shape has-background-purple-tiles">
      <div className="container">
        <div className="columns columns-are-centered">
          <div id="products" className="column is-10">
            <div className="box">
              <h3 className="product-main-header has-text-centered">
                Current initiatives
              </h3>
              <ProductGrid gridItems={products.blurbs} />
              <div className="product-call-to-action has-text-centered">
                <Link className="btn" to="/about">
                  Explore more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const BlogRollPreview = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns columns-are-centered">
          <div className="column is-10">
            <div id="blog-preview" className="has-text-centered">
              <h3 className="has-text-weight-semibold">Latest Stories</h3>
              {/* <p>Thoughts on engineering, business, and community.</p> */}
            </div>
            <BlogRoll />
            <div className="has-text-centered">
              <a
                className="btn"
                href="https://www.gued.is/garden"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutHero = ({ image }) => {
  return (
    <section
      id="section-introduction"
      className="profile section has-no-padding-bottom"
    >
      <div className="container">
        <div className="columns">
          <div className="column is-2 is-hidden-mobile" />
          <div id="hero-column-blurb" className="column is-5">
            <div id="hero-introduction-blurb">
              <h2 className="has-text-primary">
                Hola, I'm <span className="">Guedis!</span>
              </h2>
              <p className="">
                I thrive at the intersection of engineering, product, data, and
                people. Open source. Technologist.
              </p>
            </div>
          </div>
          <div id="hero-profile-column" className="column is-5">
            <Img
              fixed={image.childImageSharp.fixed}
              alt="Picture of Guedis speaking while holding a microphone"
              className="hero-profile-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutPillars = () => {
  return (
    <section
      id="section-about-pillars"
      className="section has-background-purple-tiles"
    >
      <div className="container">
        <div className="columns columns-are-centered">
          <div className="column is-10">
            <div className="columns">
              <div className="column">
                <div className="box is-100-height">
                  <h3>Builder</h3>
                  <p>
                    Motivated by making an impact, I build products and services
                    that improve our way of life.{" "}
                    {/* Explore more at{" "}
                    <a
                      className="linker"
                      href="http://www.hopeand.love"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hopeand.love
                    </a>{" "}
                    and{" "}
                    <a
                      className="linker"
                      href="http://www.palante.co"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      palante.co
                    </a> */}
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="box is-100-height">
                  <h3>Speaker</h3>
                  <p>
                    Empower others via talks on technology, applied psychology,
                    and the people/product aspect of innovation.
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="box is-100-height">
                  <h3>Advocate</h3>
                  <p>
                    I champion for a more inclusive, diverse tech community. If
                    I can help you, send me a <Link to="/contact">note</Link>!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProductGrid = ({ gridItems }) => {
  return (
    <div className="columns is-multiline">
      {gridItems.map((item) => (
        <article className="product-main column is-3" key={item.name}>
          <div className="product-image">
            <PreviewCompatibleImage imageInfo={item} />
          </div>
          <div className="product-content">
            <header className="product-content-header is-size-5">
              <Link className="has-text-primary" to={item.link}>
                {item.name}
              </Link>
            </header>
            <div className="product-content-body">
              <p>
                {item.description}
                <br />
                {item.link ? (
                  <Link className="has-text-primary" to={item.link}>
                    {item.callToAction}
                  </Link>
                ) : (
                  item.callToAction
                )}
              </p>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};

export const IndexPageTemplate = ({
  data,
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  products,
}) => (
  <div className="content">
    <AboutHero image={image} />
    <CurrentProducts products={products} />
    <BlogRollPreview />
    <AboutPillars />
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        // title={frontmatter.title}
        // heading={frontmatter.heading}
        // subheading={frontmatter.subheading}
        // mainpitch={frontmatter.mainpitch}
        // description={frontmatter.description}
        intro={frontmatter.intro}
        products={frontmatter.products}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

// if do 315, good iphone 5, but then white line at bottom when full 13'
// if do 325, vice-versa above
// can fluid be used somehow?

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fixed(width: 325, height: 325) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        products {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 128, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            description
            link
            callToAction
          }
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
